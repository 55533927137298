"use client";
import { useSession } from "next-auth/react";
import Pusher, { type Channel } from "pusher-js";
import React, {
  createContext,
  useEffect,
  useState,
  type ReactNode,
} from "react";
import { env } from "~/env";
import { getMaskedChannel } from "~/utils/user";

interface PusherContextProps {
  channel?: Channel;
}

export const LiveTriggerContext = createContext<PusherContextProps>({
  channel: undefined,
});

interface LiveTriggerProviderProps {
  children: ReactNode;
}

export const LiverTriggerProvider: React.FC<LiveTriggerProviderProps> = ({
  children,
}) => {
  const session = useSession();
  const [channel, setChannel] = useState<Channel>();

  useEffect(() => {
    if (session.data?.user.id) {
      const pusher = new Pusher(env.NEXT_PUBLIC_PUSHER_APP_KEY, {
        cluster: env.NEXT_PUBLIC_PUSHER_CLUSTER,
      });

      void getMaskedChannel(session.data.user.id).then((channelId) => {
        const subscribedChannel = pusher.subscribe(channelId);
        setChannel(subscribedChannel);
      });

      return () => {
        pusher.unbind_all();
        void getMaskedChannel(session.data.user.id).then((channelId) => {
          pusher.unsubscribe(channelId);
          setChannel(undefined);
        });
        pusher.disconnect();
      };
    }
  }, [session.data?.user.id]);

  return (
    <LiveTriggerContext.Provider value={{ channel }}>
      {children}
    </LiveTriggerContext.Provider>
  );
};
