"use client";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import { type FC } from "react";
import { useEffectOnce } from "react-use";
import { StoreKeyName } from "~/app/enums";
import packageJson from "../../../../../package.json";

const LOCAL_STORAGE_DEPLOYMENT_KEY = "hyppo-deployment";

/**
 * This component should only be use ONCE in the root layout.
 * It will ensure that the client version is corresponding to the current deployment.
 * If not, it will clear the local storage, logout the user and redirect to the default page.
 */
const VersionChecker: FC = () => {
  const router = useRouter();
  const session = useSession();

  const cleanAtoms = () => {
    for (const key in StoreKeyName) {
      localStorage.removeItem(StoreKeyName[key as keyof typeof StoreKeyName]);
    }
  };

  const checkVersion = async () => {
    const userStoredDeployment = localStorage.getItem(
      LOCAL_STORAGE_DEPLOYMENT_KEY,
    );
    const isUserOnLatestVersion = userStoredDeployment === packageJson.version;
    if (!isUserOnLatestVersion) {
      localStorage.setItem(LOCAL_STORAGE_DEPLOYMENT_KEY, packageJson.version);
      cleanAtoms();
      if (session.status !== "unauthenticated") {
        await signOut();
        router.push("/");
      }
    }
  };

  useEffectOnce(() => {
    void checkVersion();
  });

  return null;
};

export default VersionChecker;
