import { useAtom } from "jotai";
import { usePathname } from "next/navigation";
import { useMemo } from "react";
import {
  affiliateIdAtom,
  answersAtom,
  contactAtom,
  defaultContact,
  defaultNavigation,
  initialAnswers,
  initialNavigation,
  navigationAtom,
} from "~/app/onboarding/atoms";
import {
  type Answer,
  type CaseOnboardingType,
  type Navigation,
} from "~/modules/onboarding/interfaces";
import { getOnboardingTypeByPath } from "~/modules/onboarding/utils/onboardingTypeManager";

// Be careful when using redirect as it may clear all answers
// Redirecting and keeping correct answers is not supported yet

/**
 * This hook is intended to be used in case onboarding only. e.g. standard, patrimonial, senior, obo
 * It is not intended to be used with onboarding not leading to case creation such as parters onboarding.
 */
export const useOnboardingAtoms = () => {
  const currentPathname = usePathname();
  const [answers, setAnswers] = useAtom(answersAtom);
  const [navigation, setNavigation] = useAtom(navigationAtom);
  const [, setContact] = useAtom(contactAtom);
  const [, setAffiliateId] = useAtom(affiliateIdAtom);

  const currentOnboardingType: CaseOnboardingType = useMemo(() => {
    return getOnboardingTypeByPath(currentPathname);
  }, [currentPathname]);

  // Answers
  const getAnswersForType = (
    onboardingType = currentOnboardingType,
  ): Answer[] => answers[onboardingType];
  const setAnswersForType = (
    newAnswers: Answer[],
    onboardingType = currentOnboardingType,
  ) => setAnswers({ ...answers, [onboardingType]: newAnswers });

  // Navigation
  const getNavigation = (onboardingType = currentOnboardingType): Navigation =>
    navigation[onboardingType];
  const setNavigationForType = (
    newNavigation: Navigation,
    onboardingType = currentOnboardingType,
  ) => {
    setNavigation({
      ...navigation,
      [onboardingType]: newNavigation,
    });
  };

  const setUpdatedNavigation = (newNavigation: Partial<Navigation>) => {
    setNavigation({
      ...navigation,
      ...newNavigation,
    });
  };

  const setNavigationToDefault = () => {
    setNavigation(defaultNavigation);
  };

  const resetOnboardingState = (onboardingType: CaseOnboardingType) => {
    setAnswersForType(initialAnswers, onboardingType);
    setContact(defaultContact);
    setNavigationForType(initialNavigation, onboardingType);
    setAffiliateId(undefined);
  };

  return {
    setAnswers,
    getAnswersForType,
    setAnswersForType,
    getNavigation,
    setUpdatedNavigation,
    setNavigationForType,
    setNavigationToDefault,
    resetOnboardingState,
    // Be careful when using this onboardingTypeByPathname, it may not be the one stored in the local storage
    onboardingTypeByPathname: currentOnboardingType,
  };
};
